export default class Modal {
    constructor() {
        this.modalItem()
        this.modalFloor()
    }

    modalItem() {
        document.querySelectorAll('[data-exp]').forEach(list => {
            list.addEventListener('click', () => {
                const modal = document.querySelector(`#${list.dataset.exp}`)

                modal.classList.add('active')

                modal.querySelector('.title img').addEventListener('click', () => {
                    modal.classList.remove('active')
                })

                modal.querySelectorAll('.tag').forEach(tags => {
                    tags.addEventListener('click', tag => {
                        modal.querySelectorAll('.tag').forEach(tag => {
                            tag.classList[tags.target == tag ? 'toggle' : 'remove']('active')
                        })
                        tag.currentTarget.classList.add('active')

                        modal.querySelectorAll('.content').forEach(content => {
                            content.classList.remove('active')
                        })
                        const number = tag.currentTarget.classList[1].match(/\d+/)[0]
                        modal.querySelector(`.content${number}`).classList.add('active')
                    })
                })
            })
        })
    }

    modalFloor() {
        // Parcours - Open floor modal
        document.querySelectorAll('.parcours .floor li .floor_text, .parcours .floor li > picture').forEach(floorItem => {
            floorItem.addEventListener('click', e => {
                e.currentTarget.parentNode.classList.add('active')
            })
        })

        // Floor - Close all modals then return back to menu (mobile)
        document.querySelectorAll('.parcours .floor .floor_content > div:last-of-type > img').forEach(floor => {
            floor.addEventListener('click', () => {
                document.body.style.overflow = 'initial'
                document.querySelector('.parcours').classList.remove('active')
                document.querySelectorAll('.parcours li').forEach(parcours => {
                    parcours.classList.remove('active')
                })
                document.querySelector('.menu').style.opacity = 1
            })
        })

        // Floor - Close all modals then return back to menu (tablet)
        document.querySelectorAll('.floor_nav > img').forEach(floor => {
            floor.addEventListener('click', () => {
                document.querySelector('.parcours').classList.remove('active')
                document.querySelectorAll('.parcours li').forEach(parcours => {
                    parcours.classList.remove('active')
                })
                document.querySelector('.menu').style.opacity = 1
            })
        })

        // Floor - Close floor modal then return to floor menu choice
        document.querySelectorAll('.parcours .floor li .floor_content > div:last-of-type p').forEach(floor => {
            floor.addEventListener('click', e => {
                e.currentTarget.closest('li').classList.remove('active')
                document.querySelector('.parcours').classList.add('active')
            })
        })
    }
}