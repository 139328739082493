import Modal from './Modal'

document.addEventListener('DOMContentLoaded', () => {

    new Modal()

    if (document.querySelector('.page-home')) {
        const experience = document.querySelector('.js-experience')

        document.querySelector('.js-menu-experience').addEventListener('click', e => {
            e.preventDefault()
            document.body.style.overflow = 'hidden'
            document.querySelector('.menu').style.opacity = 0
            experience.classList.add('active')
        })

        document.querySelector('.js-close-experience').addEventListener('click', e => {
            e.preventDefault()
            document.body.style.overflow = 'initial'
            document.querySelector('.menu').style.opacity = 1
            experience.classList.remove('active')
        })

        const parcours = document.querySelector('.js-parcours')

        document.querySelector('.js-menu-parcours').addEventListener('click', e => {
            e.preventDefault()
            document.body.style.overflow = 'hidden'
            document.querySelector('.menu').style.opacity = 0
            parcours.classList.add('active')
        })

        document.querySelector('.js-close-parcours').addEventListener('click', e => {
            e.preventDefault()
            document.body.style.overflow = 'initial'
            document.querySelector('.menu').style.opacity = 1
            parcours.classList.remove('active')
        })
    }

    if (document.querySelector('.page-exp')) {
        document.querySelectorAll('.tag').forEach(tags => {
            tags.addEventListener('click', tag => {
                document.querySelectorAll('.tag').forEach(tag => {
                    tag.classList[tags.target == tag ? 'toggle' : 'remove']('active')
                })
                tag.currentTarget.classList.add('active')

                document.querySelectorAll('.content').forEach(content => {
                    content.classList.remove('active')
                })
                const number = tag.currentTarget.classList[1].match(/\d+/)[0]
                document.querySelector(`.content${number}`).classList.add('active')
            })
        })

        document.querySelector('.item .title img').addEventListener('click', () => {
            location.href = 'https://irisetoctave.q-r-codes.com/'
        })
    }

})